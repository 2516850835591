dmx.Component('medium-editor', {

    data: {
        value: ''
    },

    attributes: {
        editable: {
            type: Boolean,
            default: false
        },

        placeholder: {
            type: String,
            default: 'Type your text'
        },

        static: {
            type: Boolean,
            default: false
        },

        align: {
            type: String,
            default: 'center' // left|center|right
        },

        buttons: {
            type: Array,
            default: ['bold', 'italic', 'underline', 'anchor', 'h2', 'h3', 'quote']
            // bold|italic|underline|strikethrough|subscript|superscript|anchor|image|quote|pre|orderedlist|unorderedlist|indent|outdent|justifyLeft|justifyCenter|justifyRight|justifyFull|h1|h2|h3|h4|h5|h6|removeFormat|html
        },

        'disable-return': {
            type: Boolean,
            default: false
        },

        'disable-double-return': {
            type: Boolean,
            default: false
        },

        'disable-extra-spaces': {
            type: Boolean,
            default: false
        },

        'disable-spellcheck': {
            type: Boolean,
            default: false
        },

        'target-blank': {
            type: Boolean,
            default: false
        },

        'auto-link': {
            type: Boolean,
            default: false
        },

        'auto-list': {
            type: Boolean,
            default: false
        },

        fontawesome: {
            type: Boolean,
            default: false
        },

        value: {
            type: String,
            default: null
        }
    },

    render: function(node) {
        this.$node = node;
    },

    mounted: function() {
        var value = this.$node.tagName == 'TEXTAREA' ? this.$node.value.trim() : this.$node.innerHTML.trim();

        if (value.indexOf('{{') !== -1) {
            this.$addBinding(value, this.setValue.bind(this));
        }

        this.update({});
    },

    update: function(props) {
        if (JSON.stringify(props) != JSON.stringify(this.props)) {
            if (props.value != this.props.value) {
                console.log('value', props.value);
                this.setValue(this.props.value);
            }

            this.destroyEditor();

            if (this.$node.tagName == 'TEXTAREA' || this.props.editable) {
                this.initEditor();
            }
        }
    },

    updated: function() {
        if (this.editor) {
            this.set('value', this.editor.getContent());
        } else {
            var value = this.$node.tagName == 'TEXTAREA' ? this.$node.value.trim() : this.$node.innerHTML.trim();
            this.set('value', value);
        }
    },

    setValue: function(value) {
        if (this.editor) {
            this.editor.setContent(value);
        } else if (this.$node.tagName == 'TEXTAREA') {
            this.$node.value = value;
        } else {
            this.$node.innerHTML = value;
        }

        this.updated();
    },

    initEditor: function() {
        if (!this.editor) {
            var buttons = this.props.buttons;

            if (typeof(buttons) == 'string') {
                if (buttons[0] == '[') {
                    buttons = dmx.parse(buttons);
                } else {
                    buttons = buttons.split(/\s*,\s*/g);
                }
            }

            this.editor = new MediumEditor(this.$node, {
                buttonLabels: this.props.fontawesome ? 'fontawesome' : false,
                disableReturn: this.props['disable-return'],
                disableDoubleReturn: this.props['disable-double-return'],
                disableExtraSpaces: this.props['disable-extra-spaces'],
                spellcheck: !this.props['disable-spellcheck'],
                targetBlank: this.props['target-blank'],
                autoLink: this.props['auto-link'],
                toolbar: {
                    static: this.props.static,
                    sticky: this.props.static,
                    align: this.props.align,
                    updateOnEmptySelection: this.props.static,
                    buttons: buttons
                },
                anchorPreview: {
                    showWhenToolbarIsVisible: this.props.static
                },
                placeholder: {
                    text: this.props.placeholder,
                    hideOnClick: false
                },
                extensions: this.initExtensions()
            });

            this.editor.dmxComponent = this;

            this.editor.subscribe('editableInput', this.updated.bind(this));
        }
    },

    initExtensions: function() {
        var extensions = {
            imageDragging: {}
        };

        Object.keys(dmx.mediumEditor.extensions).forEach(function(name) {
            var extension = dmx.mediumEditor.extensions[name];
            var config = {};

            if (name == 'auto-list' && !this.props['auto-list']) {
                return;
            }

            if (name == 'toolbar') {
                return
            }

            if (this.$node.hasAttribute('medium:' + name)) {
                try {
                    config = dmx.parse(this.$node.getAttribute('medium:' + name));
                } catch (e) {
                    console.warn('Invalid config for ' + name);
                }
            }

            extensions[name] = new extension(config);
        }, this);

        var buttons = this.props.buttons;

        if (typeof(buttons) == 'string') {
            if (buttons[0] == '[') {
                buttons = dmx.parse(buttons);
            } else {
                buttons = buttons.split(/\s*,\s*/g);
            }
        }

        extensions.toolbar = new dmx.mediumEditor.extensions.toolbar({
            static: this.props.static,
            sticky: this.props.static,
            align: this.props.align,
            updateOnEmptySelection: this.props.static,
            buttons: buttons
        })

        return extensions;
    },

    destroyEditor: function() {
        if (this.editor) {
            this.editor.destroy();
            delete this.editor;
        }
    }

});
