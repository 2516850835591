MediumEditor.util.keyCode.LEFT = 37;
MediumEditor.util.keyCode.UP = 38;
MediumEditor.util.keyCode.RIGHT = 39;
MediumEditor.util.keyCode.DOWN = 40;

dmx.mediumEditor.Extension('handle-selection', {

    init: function () {
        this.subscribe('focus', this.checkSelection.bind(this));
        this.subscribe('editableClick', this.delay(this.checkSelection.bind(this)));
        this.subscribe('editableKeydown', this.handleEditableKeydown.bind(this));
        this.subscribe('editableKeyup', this.checkSelection.bind(this));
        this.on(this.document.documentElement, 'mouseup', this.checkSelection.bind(this));
    },

    handleEditableKeydown: function (event) {
        var node = MediumEditor.selection.getSelectionStart(this.document);
        var offset = MediumEditor.selection.getCaretOffsets(node);
        var keyCode = MediumEditor.util.getKeyCode(event);
        var isEmpty = /^(\s+|<br\/?>)?$/i;

        if (
            keyCode == MediumEditor.util.keyCode.BACKSPACE &&
            offset.left === 0 &&
            node.previousElementSibling &&
            node.previousElementSibling.tagName == 'FIGURE'
        ) {
            node.previousElementSibling.querySelector('img').click();
            if (isEmpty.test(node.innerHTML)) {
                node.parentNode.removeChild(node);
            }
            event.preventDefault();
        } else if (
            keyCode == MediumEditor.util.keyCode.BACKSPACE &&
            node.tagName == 'IMG'
        ) {
            var figure = MediumEditor.util.getClosestTag(node, 'figure');
            MediumEditor.selection.moveCursor(this.document, figure.nextElementSibling, 0);
            figure.parentNode.removeChild(figure);
            event.preventDefault();
        } else if (
            keyCode == MediumEditor.util.keyCode.ENTER &&
            MediumEditor.util.getClosestTag(node, 'figure') !== false
        ) {
            var p = this.document.createElement('p');
            p.innerHTML = '<br>';
            var figure = MediumEditor.util.getClosestTag(node, 'figure');
            figure.parentNode.parentNode.insertBefore(p, figure.parentNode.nextSibling);
            MediumEditor.selection.moveCursor(this.document, p);
            event.preventDefault();
        } else if (
            node.tagName == 'IMG' &&
            (keyCode == MediumEditor.util.keyCode.UP ||
            keyCode == MediumEditor.util.keyCode.DOWN ||
            keyCode == MediumEditor.util.keyCode.LEFT ||
            keyCode == MediumEditor.util.keyCode.RIGHT)
        ) {
            MediumEditor.selection.moveCursor(this.document, node.parentNode.querySelector('figcaption'));
        }
    },

    setContent: function (html) {
        if (/^\s*$/.test(html)) {
            return '<p><br></p>';
        }
        return html;
    },

    getContent: function (html) {
        return html.replace(/medium\-editor\-is\-selected/gi, '').replace(/class="\s*"/gi, '');
    },

    delay: function (fn) {
        return function() {
            setTimeout(function() {
                fn.call(this, arguments);
            }.bind(this), 0);
        }
    },

    destroy: function() {
        this.getEditorElements().forEach(function(element) {
            element.querySelectorAll('.medium-editor-is-selected').forEach(function(element) {
                element.classList.remove('medium-editor-is-selected');
            });
        }, this);
    },

    checkSelection: function () {
        var sel = this.document.getSelection();

        if (sel && sel.rangeCount > 0) {
            var range = sel.getRangeAt(0);
            var editor = MediumEditor.util.getContainerEditorElement(range.commonAncestorContainer)

            if (editor) {
                var block = MediumEditor.util.getTopBlockContainer(range.commonAncestorContainer);

                editor.querySelectorAll('.medium-editor-is-selected').forEach(function(node) {
                    node.classList.remove('medium-editor-is-selected');
                });

                if (!MediumEditor.util.isMediumEditorElement(block)) {
                    block.classList.add('medium-editor-is-selected');
                }

                var node = MediumEditor.selection.getSelectionStart(this.document);
                if (node && node.tagName == 'IMG') {
                    node.classList.add('medium-editor-is-selected');
                }
            }
        }
    }

});
